import React from 'react';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';
import Layout from '../layout/defaultLayout';
import Container from '../components/layout/Container';
import slugify from '../utils/slugify';
import { vblClubName } from '../../config';

const TeamContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const TeamTile = styled.div`
  max-width: 100%;
  img,
  .gatsby-image-wrapper {
    max-width: 100%;
  }
  .teamWrapper {
    margin: 10px;
    position: relative;
    border-radius: 10px;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: block;
      background: linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0) 50%);
    }
    h2 {
      position: absolute;
      bottom: 0;
      z-index: 2;
      text-align: center;
      width: 100%;
      color: #fff;
    }
  }
`;

const Teams = ({ pageContext: { teams }, data: { allContentfulPloeg } }) => {
  const teamsWithName = teams
    .filter(team => team && team.naam)
    .sort((prevTeam, nextTeam) => (prevTeam.naam > nextTeam.naam ? 1 : -1));

  // add the contentful images to the the teams
  allContentfulPloeg.edges.forEach(teamEdge => {
    const correspondingTeam = teamsWithName.filter(team => team.guid === teamEdge.node.guid)[0];
    if (correspondingTeam) {
      correspondingTeam.image = teamEdge.node.ploegfoto.fixed;
      const correspondingTeamIndex = teamsWithName.indexOf(correspondingTeam);
      teamsWithName[correspondingTeamIndex] = correspondingTeam;
    }
  });

  const seniorTeams = teamsWithName.filter(team => team.naam.includes('HSE'));
  const girlTeams = teamsWithName.filter(team => team.naam.includes('DSE'));
  const youthTeams = teamsWithName.filter(
    team => !(team.naam.includes('HSE') || team.naam.includes('DSE'))
  );

  return (
    <Layout>
      <Container>
        <h1>Ploegen</h1>
        {seniorTeams && <h2>Seniors</h2>}
        <TeamContainer>
          {seniorTeams.map(team => (
            <TeamTile key={team.guid}>
              <Link to={`/${slugify(team.naam)}`}>
                <div className="teamWrapper">
                  <h2>{team.naam.replace(vblClubName, '')}</h2>
                  <Img fixed={team.image} />
                </div>
              </Link>
            </TeamTile>
          ))}
        </TeamContainer>
        {girlTeams && <h2>Dames</h2>}
        <TeamContainer>
          {girlTeams.map(team => (
            <TeamTile key={team.guid}>
              <Link to={`/${slugify(team.naam)}`}>
                <div className="teamWrapper">
                  <h2>{team.naam.replace(vblClubName, '')}</h2>
                  <Img fixed={team.image} />
                </div>
              </Link>
            </TeamTile>
          ))}
        </TeamContainer>
        {youthTeams && <h2>Jeugd</h2>}
        <TeamContainer>
          {youthTeams.map(team => (
            <TeamTile key={team.guid}>
              <Link to={`/${slugify(team.naam)}`}>
                <div className="teamWrapper">
                  <h2>{team.naam.replace(vblClubName, '')}</h2>
                  <Img fixed={team.image} />
                </div>
              </Link>
            </TeamTile>
          ))}
        </TeamContainer>
      </Container>
    </Layout>
  );
};

export default Teams;

export const ALLTEAMIMAGES = graphql`
  query allTeamImages {
    allContentfulPloeg {
      edges {
        node {
          guid
          ploegfoto {
            fixed(width: 300, height: 200) {
              ...GatsbyContentfulFixed
            }
          }
        }
      }
    }
  }
`;
